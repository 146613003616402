import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Avatar, Button, Drawer, Layout, Menu } from "antd";
import IntraAILogo from "../../assets/images/logos/intra_ai_logo_128x128.png";
import { Link } from "react-router-dom";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { PATH } from "../../constants/paths";

const { Header } = Layout;

export const Navbar: React.FC = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const menuItems = isAuthenticated
    ? [
        {
          key: "home",
          label: <Link to="/">Home</Link>,
        },
        {
          key: "dashboard",
          label: <Link to="/dashboard">Dashboard</Link>,
        },
        {
          key: "logout",
          label: "Logout",
          onClick: logout,
        },
      ]
    : [
        {
          key: "home",
          label: <Link to={PATH.HOME}>Home</Link>,
        },
        {
          key: "login",
          label: <Link to={PATH.LOGIN}>Login</Link>,
        },
        {
          key: "register",
          label: <Link to={PATH.REGISTER}>Register</Link>,
        },
      ];

  const showDrawer = () => setDrawerVisible(true);
  const closeDrawer = () => setDrawerVisible(false);

  return (
    <>
      <Header style={styles.header}>
        <Link to={PATH.HOME}>
          <div style={styles.logo}>
            <img
              src={IntraAILogo}
              alt="LOGO"
              style={{ marginRight: "0px", height: "64px" }}
            />
            <span style={styles.intra}>Intra</span>
            <span style={styles.ai}>AI</span>
          </div>
        </Link>

        <div style={styles.menuContainer}>
          <Menu
            theme="light"
            mode="horizontal"
            style={styles.menu}
            items={menuItems}
          />
          <Button style={styles.contactUsButton}>Contact Us!</Button>

          <MenuOutlined style={styles.burgerIcon} onClick={showDrawer} />
        </div>
      </Header>

      <Drawer>
        <Menu mode="vertical" items={menuItems} />
        <Button style={styles.contactUsButton}>Contact Us!</Button>
      </Drawer>
    </>
  );
};

const styles = {
  header: {
    position: "fixed",
    zIndex: 1,
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 20px",
    borderBottom: "1px solid #f0f0f0",
  } as React.CSSProperties,
  logo: {
    fontSize: "20px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  } as React.CSSProperties,
  intra: {
    color: "#4286F4",
    marginRight: "5px",
    fontSize: "20px",
    fontWeight: "bold",
    userSelect: "none",
  } as React.CSSProperties,
  ai: {
    color: "black",
    fontSize: "20px",
    fontWeight: "bold",
    userSelect: "none",
  } as React.CSSProperties,
  menuContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  } as React.CSSProperties,
  menu: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
  } as React.CSSProperties,
  contactUsButton: {
    backgroundColor: "#4286F4",
    color: "white",
    marginLeft: "15px",
  } as React.CSSProperties,
  burgerIcon: {
    fontSize: "20px",
    display: "none",
    cursor: "pointer",
    marginLeft: "15px",
  } as React.CSSProperties,
} as { [key: string]: React.CSSProperties };

const mediaQuery = window.matchMedia("(max-width: 768px");
mediaQuery.addEventListener("change", (e) => {
  if (e.matches) {
    styles.menu.display = "none";
    styles.burgerIcon.display = "block";
  } else {
    styles.menu.display = "flex";
    styles.burgerIcon.display = "none";
  }
});
