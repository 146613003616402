import React from "react";
import { Spin } from "antd";

export const Loading: React.FC = () => (
  <div style={styles.container}>
    <Spin size="large" tip="Loading...">
      <div style={{ minHeight: "100px" }} />
    </Spin>
  </div>
);

const styles = {
  container: {
    textAlign: "center",
    paddingTop: "100px",
  } as React.CSSProperties,
};
