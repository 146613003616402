import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { AppRoutes } from "./components/routes";
import { AuthProvider } from "./contexts/AuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId="934902126598-a508g13gjnrtnoeus2igd75pd0b9hps1.apps.googleusercontent.com">
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  </GoogleOAuthProvider>
);
