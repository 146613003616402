import React from "react";
import { Layout } from "antd";
import { Navbar } from "../../components/layout/Navbar";
import { AppFooter } from "../../components/layout/Footer";

const { Content } = Layout;

export const MainLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Layout>
      <Navbar />
      <Content style={{ padding: "50px", marginTop: "64px" }}>
        {children}
      </Content>
      <AppFooter />
    </Layout>
  );
};
