export const PATH = {
  ABOUT: "/about",
  CONTACT: "/contact",
  DASHBOARD: "/dashboard",
  DOCUMENTATION: "/documentation",
  FAQ: "/faq",
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  PRICING: "/pricing",
  PROFILE: "/profile",
  PUBSUB: "/pubsub",
  SETTINGS: "/settings",
  NOT_FOUND: "/404",
};
