import { Button, Col, Layout, Row, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import intraAILogo from "../../assets/images/logos/intra_ai_logo_128x128.png";

const { Footer } = Layout;
const { Title, Text } = Typography;

export const AppFooter: React.FC = () => {
  return (
    <Footer style={styles.footer}>
      <div style={styles.logoContainer}>
        <img src={intraAILogo} alt="Intra AI Logo" style={styles.logo} />
        <span style={styles.intra}>Intra</span>
        <span style={styles.ai}>AI</span>
      </div>

      <Row gutter={[32, 32]} justify="center">
        <Col xs={24} sm={12} md={6}>
          <Title level={5}>Product</Title>
          <Space direction="vertical">
            <Link to="/home">Home</Link>
            <Link to="/pricing">Pricing</Link>
            <Link to="/faq">FAQ</Link>
            <Link to="/contact">Contact</Link>
          </Space>
        </Col>

        <Col xs={24} sm={12} md={6}>
          <Title level={5}>Resources</Title>
          <Space direction="vertical">
            <Link to="/templates">Templates</Link>
            <Link to="/docs">Docs</Link>
            <Link to="/components">Components</Link>
            <Link to="/updates">Updates</Link>
          </Space>
        </Col>

        <Col xs={24} sm={12} md={6}>
          <Title level={5}>Packages</Title>
          <Space direction="vertical">
            <Link to="/ultimate-package">Ultimate Package</Link>
            <Link to="/premium-package">Premium Package</Link>
            <Link to="/pro-package">Pro Package</Link>
          </Space>
        </Col>

        <Col xs={24} sm={12} md={6}>
          <Title level={5}>Newsletter</Title>
          <Text>
            Get notified about new updates, products, tips, and tutorials. No
            spam. You can always unsubscribe.
          </Text>
          <Button type="primary" style={styles.subscribeButton}>
            Subscribe
          </Button>
        </Col>
      </Row>

      <div style={styles.footerBottom}>
        <Text>Intra AI ©2024</Text>
      </div>
    </Footer>
  );
};

const styles = {
  footer: {
    backgroundColor: "#fff",
    padding: "40px 50px",
    borderTop: "1px solid #f0f0f0",
  } as React.CSSProperties,
  logo: {
    height: "60px",
    marginRight: "5px",
    alignSelf: "flex-start",
  } as React.CSSProperties,
  logoContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    justifyContent: "flex-start",
    paddingLeft: "0",
  } as React.CSSProperties,
  intra: {
    color: "#4286F4",
    fontSize: "24px",
    fontWeight: "bold",
    marginRight: "2px",
  } as React.CSSProperties,
  ai: {
    color: "black",
    fontSize: "24px",
    fontWeight: "bold",
  } as React.CSSProperties,
  subscribeButton: {
    marginTop: "10px",
    backgroundColor: "#4286F4",
    color: "white",
  } as React.CSSProperties,
  footerBottom: {
    textAlign: "center",
    marginTop: "40px",
    paddingTop: "20px",
    borderTop: "1px solid #f0f0f0",
  } as React.CSSProperties,
};
