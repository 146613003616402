import React, { lazy, Suspense } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { MainLayout } from "../../pages/layouts/MainLayout";
import { Loading } from "../loading";
import { PATH } from "../../constants/paths";
import { Route, Routes } from "react-router-dom";
import { DocumentationLayout } from "../layout/DocumentationLayout";

// ---> Static pages
const FaqPage = lazy(() => import("../../pages/static-pages/FaqPage"));
const HomePage = lazy(() => import("../../pages/home/HomePage"));
const PricingPage = lazy(() => import("../../pages/static-pages/PricingPage"));

// ---> Auth pages
const LoginPage = lazy(() => import("../../pages/auth-pages/LoginPage"));
const RegisterPage = lazy(() => import("../../pages/auth-pages/RegisterPage"));

// --> Documention pages
const DocumentationHomePage = lazy(
  () => import("../../pages/documentation/DocumentationHomePage")
);

// --> PubSub page
const PubSubPage = lazy(() => import("../../pages/pubsub/PubSubPage"));

// ---> Error pages
const NotFoundPage = lazy(() => import("../../pages/error-pages/NotFoundPage"));

const helmetContext = {};

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <HelmetProvider context={helmetContext}>
        <Helmet>
          {/* Global meta tags */}
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta httpEquiv="Content-Language" content="en" />
          <meta name="author" content="Intra AI" />
          <title>Intra AI</title>
          <link rel="canonical" href="https://intra-ai.de/" />
        </Helmet>

        <MainLayout>
          <Suspense fallback={<Loading />}>
            <Routes>
              {/* Static page routes */}
              <Route path={PATH.HOME} element={<HomePage />} />
              <Route path={PATH.FAQ} element={<FaqPage />} />
              <Route path={PATH.PRICING} element={<PricingPage />} />

              {/* Auth page routes */}
              <Route path={PATH.LOGIN} element={<LoginPage />} />
              <Route path={PATH.REGISTER} element={<RegisterPage />} />

              {/* Documentation routes */}
              <Route
                path={PATH.DOCUMENTATION}
                element={<DocumentationLayout />}
              >
                <Route index element={<DocumentationHomePage />} />
              </Route>

              {/* PubSub route */}
              <Route path={PATH.PUBSUB} element={<PubSubPage />} />

              {/* Error routes */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </MainLayout>
      </HelmetProvider>
    </BrowserRouter>
  );
};
